import React, { createContext, useEffect, useState } from 'react';
import useResponseHandling from '../../hooks/useResponseHandler';
import i18n from '../../i18n';
import { getPaymentTermsList } from '../../routes/paymentTermRoutes';
import useAuth from '../../hooks/useAuth';

export const PaymentContext = createContext();

export const PaymentProvider = ({ children }) => {
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();
  const [paymentTermOptions, setPaymentTermOptions] = useState([]);
  const [newPaymentTermDialogOpen, setNewPaymentTermDialogOpen] =
    useState(false);
  const [newPaymentTerm, setNewPaymentTerm] = useState({
    payment_term: 0,
  });
  const [newPaymentTermsList, setNewPaymentTermsList] = useState([]);
  const { auth } = useAuth();

  useEffect(() => {
    // Guard Clause
    if (!auth?.auth_info) {
      return;
    }

    const controller = new AbortController();
    const { signal } = controller;

    async function getPaymentTermOptions() {
      try {
        const paymentTermsList = await getPaymentTermsList();

        if (paymentTermsList.status === 200) {
          setPaymentTermOptions(paymentTermsList.data);
        }
      } catch (error) {
        handleErrorResponse(error);
      }
    }

    async function preLoadInfo() {
      await getPaymentTermOptions();
    }

    preLoadInfo().then(() => {
      controller.abort(signal);
    });
  }, [i18n.language]);

  const paymentOptions = ['cc', 'ach', 'bank', 'check'];

  return (
    <PaymentContext.Provider
      value={{
        paymentTermOptions,
        setPaymentTermOptions,
        paymentOptions,
        newPaymentTermDialogOpen,
        setNewPaymentTermDialogOpen,
        newPaymentTerm,
        setNewPaymentTerm,
        newPaymentTermsList,
        setNewPaymentTermsList,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};
