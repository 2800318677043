import { useContext, useState } from 'react';
import {
  Button,
  Box,
  FormControl,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { Form, Formik, useFormik } from 'formik';
import * as Yup from 'yup';
import { LeadContext } from '../../../context/providers/leads/LeadContextProvider.jsx';
import useResponseHandling from '../../../hooks/useResponseHandler.js';
import { useProcessingHandler } from '../../../hooks/useProcessingHandler.js';
import useUser from '../../../hooks/useUser.js';
import { useAnalyticsEventTracker } from '../../../hooks/useAnalyticsTracker.jsx';
import LanguagesData from '../../../data/LanguagesData.json';
import axios from '../../../hooks/axios/useAxios.js';
import { classes } from '../../../settings/theme.js';
import ResponseContext from '../../../context/providers/ResponseProvider.jsx';
import { StatusContent } from '../../status/StatusContent.jsx';
import UserContext from '../../../context/providers/UserInfoProvider.jsx';
import IndustriesField from '../../industries/IndustryField.jsx';
import AccountTypeField from '../../account/fields/type/AccountTypeField.jsx';
import BusinessNameTextField from '../../account/business/BusinessNameTextField.jsx';
import IndividualFirstNameField from '../../account/individual/IndividualFirstNameField.jsx';
import IndividualLastNameField from '../../account/individual/IndividualLastNameField.jsx';
import EmailField from '../../account/fields/email/EmailField.jsx';
import { trackLinkedInConversion } from '../../../helpers/trackLinkedInConversion.js';

const HowItWorksSignUpForm = ({
  initialStep = 0,
  initialType = '',
  title = 'title',
  description = 'description',
  keyPrefix = 'components.howItWorks.howItworksSignUpForm',
  transNS = 'translation',
  type = 'lead',
}) => {
  const { leadInfo, setLeadInfo } = useContext(LeadContext);
  const { host_url } = useContext(UserContext);

  const { statusContext, setStatusContext } = useContext(ResponseContext);
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();
  const { setIsLoading } = useProcessingHandler();
  const { user, setUser } = useUser();
  const { t } = useTranslation(transNS, {
    keyPrefix,
  });
  const { t: transValidation } = useTranslation('validation');
  const { t: transButtons } = useTranslation('buttons');
  const { t: transCommon } = useTranslation('common');
  const { t: transStatus } = useTranslation('translation', {
    keyPrefix: 'components.howItWorks',
  });
  const { gaEventTracker } = useAnalyticsEventTracker();

  const submitForm = async (values, actions) => {
    try {
      setIsLoading({
        status: true,
        type: 'pending',
        text: transCommon('custom_page'),
        cta: transButtons('loading'),
        callback: null,
      });
      const url = '/api/intutec/leads/signup';
      const payload = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        lead_type: values.lead_type,
        company: values.company,
        industries: values.industries.map((industry) => industry.industry_id),
        host_url,
        origin: 'website',
        coordinates: {
          latitude: user.location.latitude,
          longitude: user.location.longitude,
        },
        language: {
          code: i18n.language,
          name: LanguagesData.find(
            (language) => language.code === i18n.language,
          ).name,
        },
        state: user.location.state,
      };
      const response = await axios.post(url, JSON.stringify(payload), {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });

      const { data, status } = response;
      const { data: leadData } = data;
      if (status === 200) {
        // Lead Successfully Generated

        // Track Event on Google Tag Manager
        gaEventTracker({
          category: 'Lead Management',
          action: 'Event',
          label: 'New Lead Created',
        });

        // Track Conversion on LinkedIn
        trackLinkedInConversion('18916826');

        // Reset Form
        actions.resetForm();

        // Update User Info
        setLeadInfo({ ...leadInfo, data: leadData });

        // Update URI
        setUser({
          ...user,
          industries: values.industries,
        });

        setIsLoading({
          status: true,
          type: 'pending',
          text: transCommon('page_generated'),
          cta: transButtons('visit_page'),
          callback: () => {
            // TODO: using a router here does not work
            // We are wiping away our industries on user state because of this redirect
            window.location.href =
              leadData.lead_type === 'investor'
                ? `https://${host_url}/investor?referralID=${leadData.code}`
                : `https://${host_url}/how-it-works?referralID=${leadData.code}`;
          },
        });
      } else if (status === 241) {
        // Lead found but it is set to active
        setLeadInfo({ data: leadData });
        setStatusContext({
          open: true,
          title: transStatus('status.status_context_title', {
            name: leadData.first_name,
          }),
          content: transStatus('status.status_context_content', {
            first_name: leadData.first_name,
            last_name: leadData.last_name,
            type: leadData.lead_type,
          }),
          action: (
            <Button
              color="primary"
              variant="outlined"
              type="submit"
              sx={{ width: '200px' }}
              href={
                leadData.lead_type === 'investor'
                  ? `https://${host_url}/investor?referralID=${leadData.code}`
                  : `https://${host_url}/how-it-works?referralID=${leadData.code}`
              }
            >
              {transButtons('visit_page')}
            </Button>
          ),
        });
        setIsLoading({ status: false, type: '', text: '' });
      } else if (status === 231) {
        // Existing Lead found but is locked/in-active
        setLeadInfo({ data: leadData });
        setStatusContext({
          open: true,
          title: transStatus('status.inactive_lead_title'),
          content: transStatus('status.inactive_lead_content', {
            firstName: leadData.first_name,
            lastName: leadData.last_name,
          }),
        });
        setIsLoading({ status: false, type: '', text: '' });
      } else {
        // Lead exists but is not active
        setIsLoading({ status: false, type: '', text: '' });
        return handleRegularResponse({
          open: true,
          status: data.status,
          message: data.message,
        });
      }
    } catch (error) {
      console.error('error', error);
      setIsLoading({ status: false, type: '', text: '' });
      handleErrorResponse(error);
    }
  };

  const formik = useFormik({
    initialValues: {},
    initialTouched: {},
    values: {},
  });

  formik.initialValues = {
    first_name: '',
    last_name: '',
    company: '',
    email: '',
    emailConf: '',
    lead_type: initialType,
    industries: [],
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .min(2, transValidation('short'))
      .max(50, transValidation('long'))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z]).{2,50}$/,
        transValidation('first_matches'),
      )
      .required(transValidation('first_required')),
    last_name: Yup.string()
      .min(2, transValidation('short'))
      .max(50, transValidation('long'))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z]).{2,50}$/,
        transValidation('last_matches'),
      )
      .required(transValidation('last_required')),
    company: Yup.string().when('lead_type', {
      is: 'manufacturer',
      then: () =>
        Yup.string().required(transValidation('name.company_required')),
    }),
    industries: Yup.array()
      .min(1, transValidation('industry_min'))
      .required(transValidation('industry_required')),
    email: Yup.string()
      .required(transValidation('email.required'))
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        transValidation('email.invalid'),
      ),
    emailConf: Yup.string()
      .required(transValidation('email.comf_required'))
      .oneOf([Yup.ref('email'), null], transValidation('email.conf_match')),
    lead_type: Yup.string().required(transValidation('lead.type_required')),
  });

  const [activeStep, setActiveStep] = useState(initialStep);

  const steps = [
    {
      label: t(`steps.${type}.step1_label`),
      description: t(`steps.${type}.step1_desc`),
    },
    {
      label: t(`steps.${type}.step2_label`),
      description: t(`steps.${type}.step2_desc`),
    },
    {
      label: t(`steps.${type}.lead.tep3_label`),
      description: t(`steps.${type}.step3_desc`),
    },
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const LearnMoreSteps = ({ formik }) => {
    const { values, setFieldValue, errors, touched, handleBlur } = formik;

    if (activeStep === 0) {
      // Type selection
      return (
        <AccountTypeField
          fieldID="lead_type"
          required
          transPrefix="RoleTypeField"
        />
      );
    } else if (activeStep === 1) {
      // Industry Selection
      return <IndustriesField />;
    } else if (activeStep === 2) {
      // Company Details
      return (
        <>
          {/* Business Name */}
          <BusinessNameTextField
            required={values.lead_type === 'manufacturer'}
            transition={values.lead_type === 'manufacturer'}
          />

          <Stack
            spacing={{ xs: 1, sm: 3, md: 1 }}
            direction="row"
            sx={{ paddingTop: '10px' }}
            style={classes.root}
            alignItems="stretch"
          >
            {/* First Name */}
            <IndividualFirstNameField fieldID="first_name" required />

            {/* Last Name */}
            <IndividualLastNameField fieldID="last_name" required />
          </Stack>

          {/* Email Field */}
          <EmailField transPrefix="EmailField" required />

          {/* Email Field */}
          <EmailField
            fieldID="emailConf"
            transPrefix="EmailConfirmationField"
            required
          />
        </>
      );
    }
  };

  return (
    <Formik
      values={formik.values}
      initialValues={formik.initialValues}
      errors={formik.errors}
      validationSchema={validationSchema}
      validateOnMount={true}
      validateOnChange={true}
      enableReinitialize={true}
      onSubmit={(values, actions) => submitForm(values, actions)}
    >
      {(formik) => {
        const { isValid } = formik;
        return (
          <>
            {statusContext.open === true ? (
              <>
                <StatusContent />
              </>
            ) : (
              <Grid container item alignItems="flex-start" direction="column">
                <Grid item xs={12} width="100%">
                  <Grid
                    item
                    // className="intu__page_content"
                    sx={{ marginBottom: '2rem' }}
                  >
                    <Grid item>
                      <Typography
                        variant="h4"
                        textAlign={{ xs: 'center', md: 'left' }}
                      >
                        {t(title)}
                      </Typography>
                    </Grid>

                    {description && (
                      <Grid item sx={{ marginBottom: '2rem' }}>
                        <Typography
                          variant="body"
                          textAlign={{ xs: 'center', md: 'left' }}
                        >
                          {t(description)}
                        </Typography>
                      </Grid>
                    )}
                    {/* Stepper */}
                    <Form role="form" noValidate="novalidate">
                      <FormControl>
                        <Box
                          width="100%"
                          textAlign={{ xs: 'center', md: 'left' }}
                        >
                          <Typography variant="body" mt={2} mb={1}>
                            {steps[activeStep].description}
                          </Typography>
                          <Grid>
                            <Grid item xs={12}>
                              <Stack
                                spacing={2}
                                style={classes.root}
                                sx={{ marginBottom: '20px', marginTop: '30px' }}
                              >
                                <LearnMoreSteps formik={formik} />
                              </Stack>
                            </Grid>
                          </Grid>
                          <Box display="flex" flexDirection="row" pt={2}>
                            <Button
                              color="secondary"
                              variant="contained"
                              disabled={
                                activeStep === 0 ||
                                (initialStep === 1 && activeStep === 1)
                              }
                              onClick={handleBack}
                              mr={1}
                            >
                              {transButtons('back')}
                            </Button>
                            <Box flex="1 1 auto" />
                            {activeStep === steps.length - 1 ? (
                              <Button
                                color="primary"
                                variant="contained"
                                type="submit"
                                disabled={!isValid}
                                sx={{ width: '200px' }}
                              >
                                {transButtons('confirm')}
                              </Button>
                            ) : (
                              <Button
                                color="primary"
                                variant="contained"
                                onClick={handleNext}
                              >
                                {transButtons('next')}
                              </Button>
                            )}
                          </Box>
                        </Box>
                      </FormControl>
                    </Form>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </>
        );
      }}
    </Formik>
  );
};

export default HowItWorksSignUpForm;
