import { Typography, Grid, Link, List, ListItem } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const TermsOfUseContent = () => {
  // Translator
  const { t } = useTranslation('translation', {
    keyPrefix: 'context.general.TermsOfUseContent',
  });

  return (
    <Grid
      container
      direction="column"
      spacing={2}
      className="intu__section"
      sx={{ marginBottom: '4rem', textWrap: 'pretty' }}
      pb={20}
    >
      <Grid item xs={12}>
        <Typography variant="body1">{t('last_updated')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          {t('intro')}
          <Link className="link" href="https://in2tec.io">
            https://in2tec.io
          </Link>
          , {` ${t('other_sites')} ${t('agreement')} ${t('right_to_amend')}}`}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3">{t('limitations_of_use.title')}</Typography>
        <Typography variant="body1">{t('limitations_of_use.intro')}</Typography>
        <List
          sx={{
            listStyleType: 'disc',
            paddingLeft: '0.5rem',
            '& .MuiListItem-root': {
              display: 'list-item',
            },
          }}
        >
          <ListItem>
            <Typography variant="body1">
              {t('limitations_of_use.modify_copy')}
            </Typography>
          </ListItem>
          <ListItem>
            {' '}
            <Typography variant="body1">
              {t('limitations_of_use.remove_notations')}
            </Typography>
          </ListItem>
          <ListItem>
            {' '}
            <Typography variant="body1">
              {t('limitations_of_use.transfer_mirror')}
            </Typography>
          </ListItem>
          <ListItem>
            {' '}
            <Typography variant="body1">
              {t('limitations_of_use.abuse_disrupt')}
            </Typography>
          </ListItem>
          <ListItem>
            {' '}
            <Typography variant="body1">
              {t('limitations_of_use.harassing_material')}
            </Typography>
          </ListItem>
          <ListItem>
            {' '}
            <Typography variant="body1">
              {t('limitations_of_use.violate_laws')}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">
              {t('limitations_of_use.unauthorized_advertising')}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">
              {t('limitations_of_use.harvest_data')}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">
              {t('limitations_of_use.infringe_rights')}
            </Typography>
          </ListItem>
        </List>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3">{t('intellectual_property.title')}</Typography>
        <Typography variant="body1">
          {` ${t('intellectual_property.intro')} ${t('intellectual_property.license')} `}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3">
          {t('user_generated_content.title')}
        </Typography>
        <Typography variant="body1">
          {`${t('user_generated_content.intro')} ${t('user_generated_content.license')}`}
        </Typography>{' '}
        <br />
        <Typography variant="body1">
          {`${t('user_generated_content.termination')} ${t('user_generated_content.permission')}`}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3">{t('liability.title')}</Typography>
        <Typography variant="body1">{t('liability.intro')}</Typography>
        <br />
        <Typography variant="body1">{t('liability.no_warranties')}</Typography>
        <br />
        <Typography variant="body1">
          {t('liability.consequential_loss')}
        </Typography>{' '}
        <br />
        <Typography variant="body1">{t('liability.jurisdiction')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3">{t('accuracy_of_materials.title')}</Typography>
        <Typography variant="body1">
          {t('accuracy_of_materials.intro')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3">{t('links.title')}</Typography>
        <Typography variant="body1">{` ${t('links.intro')} ${t('links.use_at_own_risk')}`}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3">{t('right_to_terminate.title')}</Typography>
        <Typography variant="body1">{t('right_to_terminate.intro')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3">{t('severance.title')}</Typography>
        <Typography variant="body1">{t('severance.intro')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3">{t('governing_law.title')}</Typography>
        <Typography variant="body1">{t('governing_law.intro')}</Typography>
      </Grid>
    </Grid>
  );
};

export { TermsOfUseContent };
