import { Container, Typography, Grid, useMediaQuery, Box } from '@mui/material';
import { useTheme } from '@emotion/react';
import { Trans, useTranslation } from 'react-i18next';
import i18n from '../../i18n.js';
import { useContext, useEffect } from 'react';
import { LayoutBodyContext } from '../../context/providers/LayoutContextProvider.jsx';
import { SimpleZoomInOutcomponent } from '../motion/SimpleZoomInOut.jsx';
import useIntuTheme from '../../hooks/useIntuTheme.js';

// Icons
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import EngineeringIcon from '@mui/icons-material/Engineering';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';

import { ExistingCustomersByIndustry } from '../howitworks/containers/manufacturers/ExistingCustomers.jsx';
import { IndustriesOverviewComponent } from '../industries/IndustriesOverview.jsx';
import HowItWorksSignUpForm from '../../components/howitworks/forms/HowItWorksSignUpForm.jsx';

export const HomeComponent = () => {
  const { setLayoutTitle } = useContext(LayoutBodyContext);

  const { t } = useTranslation('translation', {
    keyPrefix: 'components.home.main',
  });

  const { mode } = useIntuTheme();

  useEffect(() => {
    // Set the Page Title
    setLayoutTitle(t('title'));
  }, []);

  const theme = useTheme();
  const medium = useMediaQuery(theme.breakpoints.down('md'));
  const small = useMediaQuery(theme.breakpoints.down('sm'));

  const params = new URLSearchParams(window.location.search);
  const fName = params.get('fName') || '';
  const cName = params.get('cName') || '';

  return (
    <Container component="main" maxWidth="auto" disableGutters>
      {/* How your brand is currently marketed */}
      <SimpleZoomInOutcomponent
        srcArray={[
          {
            type: 'animation',
            stateMachines: 'State Machine 1',
            src:
              mode === 'dark'
                ? 'https://in2tec.nyc3.cdn.digitaloceanspaces.com/animations/current_flow_dark_mode.riv'
                : 'https://in2tec.nyc3.cdn.digitaloceanspaces.com/animations/in2tec_flow_light_mode.riv',
            title: t('section_0.title', {
              company: cName ? `${cName}'s` : i18n.t('your', { ns: 'common' }),
            }),
            description: t('section_0.description', {
              company: cName ? `${cName}'s` : i18n.t('your', { ns: 'common' }),
            }),
            progress: [0.5, 0.8, 1],
            zoom: [0, 0.5, 1],
            stickyval: 0.6,
            unstickyval: 1,
            titleMT: 0,
            titleMB: 0,
            width: medium ? '330px' : '800px',
            height: medium ? '330px' : '900px',
            customLabels: {
              Manufacturer: cName
                ? cName
                : i18n.t('manufacturer', { ns: 'common' }),
              ProductReferals: i18n.t('product_referals', { ns: 'common' }),
              ActiveMarketing: i18n.t('active_marketing', { ns: 'common' }),
              ByIndustry: i18n.t('by_industry', { ns: 'common' }),
              ByIndustryProfessionals: i18n.t('by_industry_professsionals', {
                ns: 'common',
              }),
              SalesEngineers: i18n.t('outside_sales', { ns: 'common' }),
              Customers: i18n.t('customers', { ns: 'common' }),
              ByProductType: i18n.t('by_product_type', { ns: 'common' }),
              ByState: i18n.t('by_state', { ns: 'common' }),
              LeadsAndCustomerData: i18n.t('leads_customer_data', {
                ns: 'common',
              }),
            },
          },
        ]}
      />

      {/* How we operate your brand */}
      <SimpleZoomInOutcomponent
        srcArray={[
          {
            type: 'animation',
            stateMachines: 'State Machine 1',
            src:
              mode === 'dark'
                ? 'https://in2tec.nyc3.cdn.digitaloceanspaces.com/animations/in2tec_flow_dark_mode.riv'
                : 'https://in2tec.nyc3.cdn.digitaloceanspaces.com/animations/in2tec_flow_light_mode.riv',
            title: t('section_1.title', {
              company: cName ? `${cName}'s` : i18n.t('your', { ns: 'common' }),
            }),
            description: t('section_1.description', {
              company: cName ? `${cName}'s` : i18n.t('your', { ns: 'common' }),
            }),
            progress: [0.5, 0.8, 1],
            zoom: [0, 0.5, 1],
            stickyval: 0.6,
            unstickyval: 1,
            titleMT: 0,
            titleMB: 0,
            width: medium ? '330px' : '800px',
            height: medium ? '330px' : '900px',
            customLabels: {
              Manufacturer: cName
                ? cName
                : i18n.t('manufacturer', { ns: 'common' }),
              ProductReferals: i18n.t('product_referals', { ns: 'common' }),
              ActiveMarketing: i18n.t('active_marketing', { ns: 'common' }),
              ByIndustry: i18n.t('by_industry', { ns: 'common' }),
              ByIndustryProfessionals: i18n.t('by_industry_professsionals', {
                ns: 'common',
              }),
              SalesEngineers: i18n.t('outside_sales', { ns: 'common' }),
              Customers: i18n.t('customers', { ns: 'common' }),
              ByProductType: i18n.t('by_product_type', { ns: 'common' }),
              ByState: i18n.t('by_state', { ns: 'common' }),
              LeadsAndCustomerData: i18n.t('leads_customer_data', {
                ns: 'common',
              }),
            },
          },
        ]}
      />

      {/* Benefits */}
      <Grid
        container
        direction={{ xs: 'column', md: 'row' }}
        justifyContent={{ xs: 'center', md: 'flex-start' }}
        textAlign="center"
        spacing={8}
        sx={{ padding: '0 2rem 10rem 2rem' }}
      >
        {/* Title */}
        <Grid item xs={12}>
          <Typography variant="h1">{t('section_3.title')}</Typography>
        </Grid>
        {/* Benefit 1 */}
        <Grid item container direction="column" xs={12} md={4}>
          <Grid item>
            <GpsFixedIcon sx={{ fontSize: '6rem' }} />
          </Grid>
          <Grid item>
            <Typography variant="h6">
              {t('section_3.benefits.benefit_1.title')}
            </Typography>
            <Typography variant="body1">
              {t('section_3.benefits.benefit_1.description')}
            </Typography>
          </Grid>
        </Grid>
        {/* Benefit 2 */}
        <Grid item container direction="column" xs={12} md={4}>
          <Grid item>
            <AttachMoneyIcon sx={{ fontSize: '6rem' }} />
          </Grid>
          <Grid item>
            <Typography variant="h6">
              {t('section_3.benefits.benefit_2.title')}
            </Typography>
            <Typography variant="body1">
              {t('section_3.benefits.benefit_2.description', {
                name: cName ? cName : i18n.t('manufacturer', { ns: 'common' }),
              })}
            </Typography>
          </Grid>
        </Grid>
        {/* Benefit 3 */}
        <Grid item container direction="column" xs={12} md={4}>
          <Grid item>
            <EngineeringIcon sx={{ fontSize: '6rem' }} />
          </Grid>
          <Grid item>
            <Typography variant="h6">
              {t('section_3.benefits.benefit_3.title')}
            </Typography>
            <Typography variant="body1">
              {t('section_3.benefits.benefit_3.description')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {/* Industries we are active in */}
      <Grid
        container
        direction="column"
        justifyContent="center"
        textAlign="center"
        x={{ width: '100%', padding: 0, margin: 0, mb: '3rem' }}
      >
        <Grid item textAlign="center" sx={{ marginBottom: '2rem' }}>
          <Typography variant="h1">{t('section_5.title')}</Typography>
          <Typography variant="body1">{t('section_5.description')}</Typography>
        </Grid>
      </Grid>
      <IndustriesOverviewComponent sx={{ margin: '0 0 5rem 0' }} />

      {/* Our Customer Base */}
      <Grid
        container
        direction="column"
        justifyContent="center"
        textAlign="center"
        sx={{ width: '100%', padding: 0, margin: 0, pb: '10rem' }}
      >
        <Grid item textAlign="center">
          <Typography variant="h1">{t('section_4.title')}</Typography>
          <Typography variant="body1">
            {t('section_4.description', {
              name: cName ? cName : i18n.t('your_company', { ns: 'common' }),
            })}
          </Typography>
        </Grid>
      </Grid>

      <ExistingCustomersByIndustry
        title={false}
        description={false}
        legend={true}
        sx={{ margin: '0 0 10rem 0' }}
      />

      {/* Contact Us */}
      <Box
        sx={{
          width: { xs: '80%', sm: '80%', md: '60%' },
          margin: '0 auto',
          paddingBottom: { xs: '150px', sm: '150px', md: '100px' },
        }}
      >
        <HowItWorksSignUpForm
        // title={t('section_6.title')}
        // description={t('section_6.description')}
        />
      </Box>
    </Container>
  );
};
