import { useContext, useState } from 'react';
import find from '../../assets/find.png';
import axios from '../../hooks/axios/useAxios.js';
import { classes } from '../../settings/theme.js';
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import { Typography } from '@mui/material';
import useResponseHandling from '../../hooks/useResponseHandler.js';
import { useProcessingHandler } from '../../hooks/useProcessingHandler.js';
import {
  useAnalyticsEventTracker,
  useAnalyticsPageViewTracker,
} from '../../hooks/useAnalyticsTracker.jsx';
import { Form, Formik, useFormik } from 'formik';
import * as Yup from 'yup';
import { toTitleCase } from '../../helpers/toTitleCase.js';
import useUser from '../../hooks/useUser.js';

import { useTranslation, Trans } from 'react-i18next';
import i18n from 'i18next';
import LanguagesData from '../../data/LanguagesData.json';
import { InvestorContent } from './LeadContent/InvestorContent.jsx';
import { StatusContent } from '../status/StatusContent.jsx';

import ResponseContext from '../../context/providers/ResponseProvider.jsx';
import { LeadContext } from '../../context/providers/leads/LeadContextProvider.jsx';
import UserContext from '../../context/providers/UserInfoProvider.jsx';
import EmailField from '../account/fields/email/EmailField.jsx';
import IndividualLastNameField from '../account/individual/IndividualLastNameField.jsx';
import IndividualFirstNameField from '../account/individual/IndividualFirstNameField.jsx';

const LearnMoreContext = () => {
  const { leadInfo, setLeadInfo } = useContext(LeadContext);
  const { host_url } = useContext(UserContext);
  const { setStatusContext } = useContext(ResponseContext);
  const { handleRegularResponse, handleErrorResponse } = useResponseHandling();
  const { setIsLoading } = useProcessingHandler();
  const { user } = useUser();

  const { t } = useTranslation('translation', {
    keyPrefix: 'components.howItWorks.learn_more_context.investor',
  });
  const { t: transButtons } = useTranslation('buttons');
  const { t: transValidation } = useTranslation('validation');
  const { gaEventTracker } = useAnalyticsEventTracker();

  const formik = useFormik({
    initialValues: {},
    initialTouched: {},
    values: {},
  });

  formik.initialValues = {
    ...leadInfo,
    first_name: '',
    last_name: '',
    company: '',
    email: '',
    emailConf: '',
    type: 'investor',
    company_type: '',
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .min(2, transValidation('short'))
      .max(50, transValidation('long'))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z]).{2,50}$/,
        transValidation('name.first_matches'),
      )
      .required(transValidation('name.first_required')),
    last_name: Yup.string()
      .min(2, transValidation('short'))
      .max(50, transValidation('long'))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z]).{2,50}$/,
        transValidation('name.last_matches'),
      )
      .required(transValidation('name.last_required')),
    company: Yup.string().required(transValidation('name.company_required')),
    email: Yup.string()
      .required(transValidation('email.required'))
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        transValidation('email.invalid'),
      ),
    emailConf: Yup.string()
      .required(transValidation('email.comf_required'))
      .oneOf([Yup.ref('email'), null], transValidation('email.comf_match')),
    company_type: Yup.string().required(
      transValidation('investor.type_required'),
    ),
  });

  // Handle form submission process
  async function submitForm(values, actions) {
    setIsLoading({
      status: true,
      type: 'spinner',
      text: t('submit_loading'),
    });
    const controller = new AbortController();
    const { signal } = controller;
    const { latitude, longitude } = user.location;

    const url = '/api/intutec/leads/signup';
    const payload = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      lead_type: values.type,
      company: values.company,
      host_url,
      origin: 'website',
      company_type: values.company_type,
      coordinates: {
        latitude,
        longitude,
      },
      language: {
        code: i18n.language,
        name: LanguagesData.find((language) => language.code === i18n.language)
          .name,
      },
    };

    try {
      const response = await axios.post(url, JSON.stringify(payload), {
        signal,
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });

      const { data, status } = response;
      const leadInfo = data.data;
      if (status === 200) {
        gaEventTracker({
          category: 'Investor Management',
          action: 'Event',
          label: 'New Investor Request',
        });

        actions.resetForm();

        // Update User Info
        setLeadInfo(leadInfo);

        const leadName = `${leadInfo?.first_name && leadInfo.first_name}${leadInfo?.last_name && ` ${leadInfo.last_name}`}!`;
        setStatusContext({
          open: true,
          title: t('new_title', {
            lead: leadInfo.company ? leadInfo.company : leadName,
          }),
          content: t('new_content', { email: leadInfo?.email }),
          action: null,
        });
      } else if (status === 247) {
        // Lead already exists & is active
        setLeadInfo(leadInfo);
        setStatusContext({
          open: true,
          title: `${transValidation('exists_title')} ${leadInfo?.first_name ? leadInfo.first_name : leadInfo.company}!`,
          content: `${leadInfo?.first_name || leadInfo?.last_name ? `${leadInfo.first_name} ${leadInfo.last_name}` : leadInfo.company} , you already requested a pitch deck and your page is ready and wating for you...`,
          action: (
            <Grid item xs={6}>
              <Button variant="outlined" href={leadInfo.link}>
                {t('buttons.visit_page')}
              </Button>
            </Grid>
          ),
        });
      } else if (status === 248) {
        // Lead already exists but is not active
        setLeadInfo(leadInfo);
        setStatusContext({
          open: true,
          title: `${transValidation('inactive_title')} ${leadInfo?.first_name ? leadInfo.first_name : leadInfo.company}!`,
          content: `${leadInfo?.first_name || leadInfo?.last_name ? `${leadInfo.first_name} ${leadInfo.last_name}` : leadInfo.company}, we are still working on your personal experience and will send you a notification as soon as your page is ready...`,
          action: null,
        });
      } else {
        handleRegularResponse({
          open: true,
          status: data.status,
          message: data.message,
        });
      }
    } catch (error) {
      handleErrorResponse(error);
      console.errro('error', error);
    } finally {
      controller.abort();
      setIsLoading({ status: false, type: '', text: '' });
    }
  }

  const [activeStep, setActiveStep] = useState(0);

  // Investor Steps
  const steps = [
    {
      label: t('step_one_label'),
      description: t('step_one_desc'),
    },
    {
      label: t('step_two_label'),
      description: t('step_two_desc'),
    },
    {
      label: t('step_three_label'),
      description: t('step_three_desc'),
    },
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const InvestorSteps = (props) => {
    const { values, setFieldValue, errors, touched, handleBlur } = props.formik;

    if (activeStep === 0) {
      // Investor Type Selection
      return (
        <TextField
          select
          required
          label={t('type.label')}
          type="text"
          name="company_type"
          id="company_type"
          variant="outlined"
          value={values?.company_type}
          onChange={(e) => {
            setFieldValue(e.target.name, e.target.value);
          }}
          onBlur={handleBlur}
          error={errors?.type && touched?.type ? true : false}
          helperText={errors?.type && touched?.type ? errors?.type : null}
        >
          <MenuItem key={1} value="Accelerator">
            {t('type.item_one')}
          </MenuItem>
          <MenuItem key={2} value="Private Equity Firm">
            {t('type.item_two')}
          </MenuItem>
          <MenuItem key={3} value="Equity Fund">
            {t('type.item_three')}
          </MenuItem>
          <MenuItem key={4} value="Other">
            {t('type.item_four')}
          </MenuItem>
        </TextField>
      );
    } else if (activeStep === 1) {
      return (
        <TextField
          required={values.type === 'manufacturer'}
          id="company"
          name="company"
          className="form-select-field"
          aria-invalid={errors.company ? 'true' : 'false'}
          aria-describedby="uidnote"
          variant="outlined"
          label={t('firm_name.label')}
          margin="dense"
          type="text"
          placeholder={t('firm_name.placeholder')}
          onChange={(e) => {
            setFieldValue(e.target.name, e.target.value);
          }}
          onBlur={handleBlur}
          value={values?.company}
          error={errors?.company && touched?.company ? true : false}
          helperText={
            errors?.company && touched?.company ? errors.company : null
          }
        />
      );
    } else if (activeStep === 2) {
      // Personal Details
      return (
        <>
          <Stack
            spacing={{ xs: 1, sm: 3, md: 1 }}
            direction="row"
            sx={{ paddingTop: '10px' }}
            style={classes.root}
            alignItems="stretch"
          >
            {/* First Name */}
            <IndividualFirstNameField fieldID="first_name" />

            {/* Last Name */}
            <IndividualLastNameField fieldID="last_name" />
          </Stack>
          {/* Email */}

          <EmailField transPrefix="EmailField" />
          {/* Email Confirmation*/}

          <EmailField
            fieldID="emailConf"
            transPrefix="EmailConfirmationField"
          />
        </>
      );
    }
  };

  return (
    <Formik
      values={formik.values}
      initialValues={formik.initialValues}
      errors={formik.errors}
      validationSchema={validationSchema}
      validateOnMount={true}
      validateOnChange={true}
      enableReinitialize={true}
      onSubmit={(values, actions) => submitForm(values, actions)}
    >
      {(formik) => {
        return (
          <Grid
            container
            direction={{ xs: 'column-reverse', md: 'row', height: '100%' }}
            alignItems="flex-start"
            mt="65px"
          >
            <Grid
              item
              className="intu__header-image"
              xs={12}
              md={6}
              alignItems="flex-end"
              sx={{
                marginTop: 'auto',
                bottom: 0,
                padding: '3rem 2rem 0 2rem',
              }}
            >
              <img src={find} alt={transButtons('learm_more')} />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              container
              direction="row"
              alignContent="flex-start"
              sx={{ padding: '3rem 2rem 0rem 2rem' }}
            >
              <Grid item xs={12}>
                <Typography variant="h1">{t('step_h1')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body">
                  <Trans t={t} i18nKey={'step_body'} />
                </Typography>
              </Grid>
              <Divider sx={{ width: '100%' }} />
              <Grid
                item
                className="intu__page_content"
                sx={{ minHeight: '370px', marginTop: '30px' }}
              >
                {/* Stepper */}
                <Form role="form" noValidate="novalidate">
                  <FormControl>
                    <Box width="100%">
                      <>
                        <Typography variant="body" sx={{ mt: 2, mb: 1 }}>
                          {steps[activeStep].description}
                        </Typography>
                        <Grid>
                          <Grid item xs={12}>
                            <Stack
                              spacing={2}
                              style={classes.root}
                              sx={{ marginBottom: '20px', marginTop: '30px' }}
                            >
                              <InvestorSteps formik={formik} />
                            </Stack>
                          </Grid>
                        </Grid>
                        <Box display="flex" flexDirection="row" pt={2}>
                          <Button
                            color="tertiary"
                            variant="contained"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                          >
                            {transButtons('back')}
                          </Button>
                          <Box flex="1 1 auto" />
                          {activeStep === steps.length - 1 ? (
                            <Button
                              variant="contained"
                              type="submit"
                              disabled={!formik.isValid}
                              sx={{ width: '200px' }}
                            >
                              {transButtons('learn_more')}
                            </Button>
                          ) : (
                            <Button variant="contained" onClick={handleNext}>
                              {transButtons('next')}
                            </Button>
                          )}
                        </Box>
                      </>
                    </Box>
                  </FormControl>
                </Form>
              </Grid>
            </Grid>
          </Grid>
        );
      }}
    </Formik>
  );
};

const HowItWoksInvestorContent = () => {
  // Context
  const { leadInfo } = useContext(LeadContext);
  const { statusContext } = useContext(ResponseContext);

  // google page view tracking tracking
  const { gaPageViewTracker } = useAnalyticsPageViewTracker();
  gaPageViewTracker({
    type: 'pageview',
    page: document.location.pathname,
    name: 'Investors Page',
  });

  return (
    <>
      {statusContext.open === true ? (
        <StatusContent />
      ) : leadInfo?.type === 'none' || leadInfo?.lead_type === '' ? (
        <LearnMoreContext />
      ) : (
        <InvestorContent />
      )}
    </>
  );
};

export { HowItWoksInvestorContent };
